import React, { useState } from 'react';
import './Home.css';
import profilePic from '../../assets/profile-pic-smile.jpg';
import video01 from '../../assets/SpotifyAppVideo.mp4';
import video02 from '../../assets/SpaceInvaderVideo.mp4';
import video03 from '../../assets/GoalGetterVideo.mp4';
import video04 from '../../assets/BlogCMSVideo.mp4';
import linkedIn from "../../assets/In-Blue-26.png";
import woodlandDesigns from "../../assets/WoodlandDesigns02.JPG";
import buzzySweets from "../../assets/BuzzySweetsHomePage02.JPG";
import softwareDegrees from "../../assets/softwareDegreesSite.JPG";

function Home() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="container">
      <header>
        <div className="profile-header">
          <img src={profilePic} alt="Profile" width="90" height="90" />
          <div className="description-me">
            <h1 className="name">Paul Rose</h1>
            <p className="title">Web Developer</p>
          </div>
        </div>
        <nav>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <div className="portfolio" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <a href="#portfolio">Portfolio</a>
            {isDropdownVisible && (
              <div className="dropdown">
                <a href="#websites">Websites</a>
                <a href="#projects">Projects</a>
                <a href="#seo-success">SEO Success</a>
              </div>
            )}
          </div>
          <a href="#contact">Contact</a>
        </nav>
      </header>

      <section id="about">
        <h1>About Me</h1>
        <p>
        I am passionate about building innovative, user-friendly, and visually stunning websites. With a strong foundation in React and Next.js, I specialize in transforming ideas into seamless digital experiences that are both functional and aesthetically pleasing. Beyond development, I have a proven track record of driving SEO success, having contributed to projects that achieved top rankings on competitive search terms. I thrive in dynamic environments where creativity and problem-solving intersect and am eager to contribute my skills to projects that make a meaningful impact.
        </p>
      </section>

      <section id="portfolio">
        <h1>Portfolio</h1>

        {/* Website Section */}
        <div id="websites">
          <h2>Websites</h2>
          <div className="tile-section">
            <div className="project slide-up">
              <div className="project-tile">
                <h3>Woodland Designs Website</h3>
                <p>Website that sells artwork</p>
                <img src={woodlandDesigns} alt="Woodland Designs" width="500" height="440" />
                <a className="view-button woodlandDesigns" href="https://murrinwoodlanddesigns.com">VIEW SITE</a>
                <p className="tech-stack">
                  <strong>Tech Stack:</strong> Next.js, Redux Toolkit, Node.js, Cloud Firestore, Vercel, Square API, Brevo API, Tailwind CSS, Typescript, Customized CMS
                </p>
              </div>
            </div>

            <div className="project slide-up">
              <div className="project-tile">
                <h3>Buzzy Sweets Website</h3>
                <p>Website that sells baked goods</p>
                <img src={buzzySweets} alt="Buzzy Sweets" width="500" height="440" />
                <a className="view-button buzzySweets" href="https://buzzysweets.com">VIEW SITE</a>
                <p className="tech-stack">
                  <strong>Tech Stack:</strong> React, Redux Toolkit, Node.js, Cloud Firestore, Google Cloud Run, Square API, Brevo API
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Project Section */}
        <div id="projects">
          <h2>Projects</h2>
          <div className="tile-section">
            <div className="project slide-up">
              <div className="project-tile">
                <h3>Goal Getter App</h3>
                <p>Goal Getter App helps set and achieve goals</p>
                <video controls="controls" width="320" height="240">
                  <source src={video03} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <a href="https://github.com/ptrose78/GoalsApp">VIEW PROJECT</a>
                <p className="tech-stack">
                  <strong>Tech Stack:</strong> React, Node.js, Express, MySQL
                </p>
              </div>
            </div>

            <div className="project slide-up">
              <div className="project-tile">
                <h3>Blog CMS</h3>
                <p>Light-weight blog CMS to create, edit, delete, and manage blog posts</p>
                <video controls="controls" width="320" height="240">
                  <source src={video04} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <a href="https://github.com/ptrose78/BlogCMS">VIEW PROJECT</a>
                <p className="tech-stack">
                  <strong>Tech Stack:</strong> Next.js, Tailwind CSS, Typescript, Vercel, Neon Serverless Postgres, JWT authentication
                </p>
              </div>
            </div>

            <div className="project slide-up">
              <div className="project-tile">
                <h3>Playlist App</h3>
                <p>Playlist app that requests data from Spotify API</p>
                <video controls="controls" width="320" height="240">
                  <source src={video01} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <a href="https://github.com/ptrose78/SpotifyApp">VIEW PROJECT</a>
                <p className="tech-stack">
                  <strong>Tech Stack:</strong> React, Node.js
                </p>
              </div>
            </div>

            <div className="project slide-up">
              <div className="project-tile">
                <h3>Video Game</h3>
                <p>Video game inspired by Space Invaders</p>
                <video controls="controls" width="320" height="240">
                  <source src={video02} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <a href="https://github.com/ptrose78/SpaceInvaderGame">VIEW PROJECT</a>
                <p className="tech-stack">
                  <strong>Tech Stack:</strong> React, Node.js
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="seo-success">
      <h2>SEO Success</h2>
      <div className="seo-content">
        <img src={softwareDegrees} alt="Software Degrees" width="500" height="440" />
        <div className="seo-text">
        <p>
          I had the privilege of contributing to 
          <a href="https://www.softwaredegrees.org/" target="_blank" rel="noopener noreferrer"><strong> SoftwareDegrees.org</strong></a>, 
          a comprehensive resource website for software degree programs. My role focused on creating much of the written content for the site - crafting clear, engaging, and informative material tailored to its audience. Additionally, my work supported the implementation of SEO best practices, helping to optimize the content for improved search engine rankings.
        </p>
        <p>
          The website achieved remarkable success, ranking as the top result on Google for the highly competitive search term 
          <strong> "software degrees,"</strong> highlighting my ability to create impactful content that enhances visibility, engagement, and user trust.
        </p>
        </div>
      </div>
    </section>

      <footer id="contact">
        <h2 className="contact-header">Contact</h2>
        <p className="email">paultrose1@gmail.com</p>
        <div className="social-icons-container">
          <a href="https://www.linkedin.com/in/paul-rose-a50b1b24a/" target="_blank" rel="noopener noreferrer">
            <img src={linkedIn} alt="LinkedIn Profile" />
          </a>
        </div>
        <p className="closer">Copyright © 2025 Paul Rose. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
